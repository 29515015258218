import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Sidebar from '../../components/sidebar'

const GuidePage = () => {
  return(
  <Layout>
  <SEO title="Guide" />
  <div class="grid grid-cols-1 md:grid-cols-4 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
      <div class="grid grid-cols-1 md:col-span-3 md:mB-5 mx-3"> {/** first element */}
      <h1 class="text-2xl font-bold mb-2 md:mt-5">Guide de Transformateur</h1>
      <div class="border-solid border-2 border-red-500 w-1/12 mb-3"></div>
            <div class="mt-3 gap-2 grid">
            Ce guide contient les indications nécessaires pour transformer les éléments en KRION® de la manière correcte et sans prendre de risques. Il s’agit des procédures adéquates pour garantir la satisfaction du client.Ce manuel ne se veut pas exhaustif. Bien que les informations que celui-ci expose soient suffisantes pour réaliser la plupart de vos projets, N’attendez plus, allez vite voir …
            </div>
      </div>
      <div>
        <Sidebar />
      </div>
    </div>
</Layout>
)
}
export default GuidePage